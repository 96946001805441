@import "./Colors.scss";


.cart {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5;

    .backdrop {
        position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 6;
    cursor: pointer;
    }

    .cart-content {

        .btn-close {
            display: none;
        }
        position: fixed;
        width: 400px;
        right: 0;
        top: 0;
        height: 100%;
        background-color: rgb(240,240,240);
        z-index: 10 !important;
        padding: 20px;
        padding-top: 0;
        overflow-y: auto;

        animation-name: show-menu-from-right;
        animation-duration: 0.5s;
        animation-iteration-count: 1;

        @keyframes  show-menu-from-right {
            0% {
                transform: translate(100%,0);

            }

            100% {
                transform: translate(0,0);
            }
        }


        .subtotal {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 10;
            background-color: white;
            padding-top: 20px;
        }

        .cart-item {
            position: relative;
            padding: 10px;
            background-color: white;
            border-radius: 10px;
            .image {
            width: 100%;
            height: 100%;


            
            }

            .quantity-selector {
                input {
                    text-align: center !important;
                    max-width: 60px !important;
                    padding: 5px;
                }
            }
            

            .properties {
                .property-item {
                    .col-4 {
                        background-color: rgb(175, 175, 175);
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
    
                    .col-8 {
                        background-color: lightgray;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .close {
                position: absolute;
                right: 5px;
                top: 5px;
                z-index: 9;
            }
        }
    }
}


.nav-bar {
    background-color: $primaryColor;
    padding: 20px;
    z-index: 3;
    position: sticky;
    top: 0;
    left: 0;

    .menu {
        i {
            color: white;
        }
    }

    .cart {
        position: relative;
        i {
            color: white;
        }

        .indicator {
            position: absolute;
            transition: 0.3s;
            left: 0;
            bottom: 0;
            transform: translate(-50%, 50%);
            width: 25px;
            height: 25px;
            background-color: rgb(255, 255, 255);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .logo {
        img {
            width: 150px;
            cursor: pointer;
        }
    }

    .input-group {
        .input-group-text {
            background-color: white;
         
            border: none !important;
        }

        .form-control {
            border: none !important;
        }
    }
   
}



.menu {
    position: relative;

    .backdrop {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0,0,0,0.3);
        z-index: 4;
        left: 0;
        top: 0;
        cursor: pointer;
    }


    .menu-content {
        position: fixed;
        z-index: 5;
        background-color: white;
        left: 0;
        top: 0;
        width: 400px;
        height: 100%;
        animation-name: show-menu-from-left;
        animation-duration: 0.5s;
        animation-iteration-count: 1;

        @keyframes  show-menu-from-left {
            0% {
                transform: translate(-100%,0);

            }

            100% {
                transform: translate(0,0);
            }
        }
        
        .header {
            padding: 20px;
            background-color: $primaryColor;
            position: fixed;
            position: relative;

            h2 {
                color: white;
            }

            .btn-close{
                display: none;
            }
        }

        .body {

            .session-block {
                padding: 20px;
            }
            .menu-item {
                padding: 20px;
                transition: 0.3s;
                cursor: pointer;
                font-size: 20px;

                .active {
                    background-color: rgb(240,240,240);
                }
            }
        }

       
    }
}


@media screen and (max-width:800px){

    .menu {
        position: relative;
    
        .backdrop {
            width: 100%;
            height: 100%;
            position: fixed;
            background-color: rgba(0,0,0,0.3);
            z-index: 4;
            left: 0;
            top: 0;
            cursor: pointer;
        }
    
    
        .menu-content {
            position: fixed;
            z-index: 5;
            background-color: white;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            animation-name: show-menu-from-left;
            animation-duration: 0.5s;
            animation-iteration-count: 1;
    
            @keyframes  show-menu-from-left {
                0% {
                    transform: translate(-100%,0);
    
                }
    
                100% {
                    transform: translate(0,0);
                }
            }
            
            .header {
                padding: 20px;
                background-color: $primaryColor;
                position: relative;
                padding-top: 40px;
                

                .btn-close {
                    display: block;
                    position: absolute;
                    right: 5px;
                    top: 5px;

                     i {
                         font-size: 30px;
                         color: white;
                     }
                }
    
                h2 {
                    color: white;
                }
            }
    
            .body {
    
                .session-block {
                    padding: 20px;
                }
                .menu-item {
                    padding: 20px;
                    transition: 0.3s;
                    cursor: pointer;
                    font-size: 20px;
    
                    .active {
                        background-color: rgb(240,240,240);
                    }
                }
            }
    
           
        }
    }




    .cart {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 5;
    
        .backdrop {
            position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.3);
        z-index: 6;
        cursor: pointer;
        }
    
        .cart-content {
            position: fixed;
            width: 100%;
            right: 0;
            top: 0;
            height: 100%;
            background-color: rgb(240,240,240);
            z-index: 10 !important;
            padding: 20px;
            padding-top: 0;
            overflow-y: auto;
            padding-top: 60px;


            .btn-close {
                display: block;
                position: absolute;
                right: 5px;
                top: 5px;
                z-index: 15;

                 i {
                     font-size: 30px;
                 }
            }
    
            animation-name: show-menu-from-right;
            animation-duration: 0.5s;
            animation-iteration-count: 1;
    
            @keyframes  show-menu-from-right {
                0% {
                    transform: translate(100%,0);
    
                }
    
                100% {
                    transform: translate(0,0);
                }
            }
    
    
            .subtotal {
                position: sticky;
                top: 0;
                left: 0;
                z-index: 10;
                background-color: white;
                padding-top: 20px;
            }
    
            .cart-item {
                position: relative;
                padding: 10px;
                background-color: white;
                border-radius: 10px;
                .image {
                width: 100%;
                height: 100%;
    
    
                
                }
    
                .quantity-selector {
                    input {
                        text-align: center !important;
                        max-width: 60px !important;
                        padding: 5px;
                    }
                }
                
    
                .properties {
                    .property-item {
                        .col-4 {
                            background-color: rgb(175, 175, 175);
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        }
        
                        .col-8 {
                            background-color: lightgray;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
    
                .close {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    z-index: 9;
                }
            }
        }
    }



   
}