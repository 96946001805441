@import "./Colors.scss";


.fade-in {
    animation-delay: fade-in;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}