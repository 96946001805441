@import "./Colors.scss";


.modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;

    .backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        cursor: pointer;
        z-index: 6;
    }

    .modal-content {
        position: fixed;
        width: 50%;
        padding: 20px;
        border-radius: 10px;
        z-index: 8;

        .image {
            height: 200px;
            width: 100%;
        }

        .close {
            position: absolute;
            right: 20px;
            top: 20px;
        }


        .properties {
            .property-item {
                .col-4 {
                    background-color: rgb(175, 175, 175);
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                .col-8 {
                    background-color: lightgray;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}


.auth {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: rgb(244,244,244);
    .auth-panel {
        min-width: 50%;
        background-color: white;
        

        .form-control {
            background-color: rgb(244,244,244);

            &:focus {
                border-color: $primaryColor;
            }
        }
        .header {
            padding: 20px;
            background-color: $primaryColor;

            .selectable-element {
                padding: 10px;
                cursor: pointer;
                min-width: 150px;
                transition: 0.3s;
                border-bottom: 3px solid transparent;

                &.active {
                    border-bottom: 3px solid white;
                    
                }
                 p{
                     color: white;
                     font-weight: 500;
                 }
            }
        }

        .body {
            padding: 20px;
        }
    }
}


.product-item {
    max-width: 200px;
    border-radius: 5px;
    overflow: hidden;
    background-color: white;
    cursor: pointer;
    position: relative;

    .favorite {
        left: 10px;
        bottom: 10px;
        position: absolute;
    }

    .add-to-cart {
        position: absolute;
        right:10px;
        bottom: 10px;
        max-width: 60%;

        input {
            text-align: center;
            padding: 5px;
            min-width: 60px;
        }
    }
    .image {
        width: 200px;
        height: 200px;
    }

    .body {
        background-color: white !important;
        padding-bottom: 60px !important;
    }
}


.product-item-add {
    max-width: 200px;
    width: 200px;
}


.home {
    .carousel{
        max-height: 300px;
        overflow: hidden;
        .carousel-item {
            img {
                max-height: 300px;
                margin-left: 50%;
                transform: translate(-50%,0);
            }
        }
    }

    .body {
        padding: 40px;
        background-color: rgb(240,240,240);
    }
}



.search-module {
    padding: 40px;
    background-color: rgb(240,240,240);
}

.favorites {
    padding: 40px;
    background-color: rgb(240,240,240);
}

.products {
    padding: 40px;
    background-color: rgb(240,240,240);
}


@media screen and (max-width:800px){

    
.home {
    .carousel{
        max-height: 150px;
        overflow: hidden;
        .carousel-item {
            img {
                max-height: 150px;
                margin-left: 50%;
                transform: translate(-50%,0);
            }
        }
    }

    .body {
        padding: 40px;
        background-color: rgb(240,240,240);
    }
}



.modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;

    .backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        cursor: pointer;
        z-index: 6;
    }

    .modal-content {
        position: fixed;
        width: 100%;
        padding: 20px;
        border-radius: 0px;
        z-index: 8;

        .image {
            height: 200px;
            width: 100%;
        }

        .close {
            position: absolute;
            right: 20px;
            top: 20px;
        }


        .properties {
            .property-item {
                .col-4 {
                    background-color: rgb(175, 175, 175);
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                .col-8 {
                    background-color: lightgray;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

}