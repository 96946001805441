@import "./Animations.scss";
@import "./Boilerpolate.scss";
@import "./Buttons.scss";
@import "./Colors.scss";
@import "./FlexTools.scss";
@import "./Navbar.scss";
@import "./Sections.scss";
@import "./Loaders.scss";

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

* {
    box-sizing: border-box;
}


.form-control {
    box-shadow: none !important;
    outline: none !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}


.btn {
    box-shadow: none !important;
    outline: none !important;

    &:active {
        box-shadow: none !important;
    outline: none !important;
    }

    &:focus {
        box-shadow: none !important;
    outline: none !important;
    }
}